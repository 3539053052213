import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { injectIntl, FormattedMessage } from "react-intl"
import filesize from "filesize"

const Download = ({
  extension,
  iconName,
  size,
  title,
  fileName,
  ...other
}) => {
  if (iconName) {
    return (
      <a
        href={fileName}
        target="_blank"
        rel="noopener noreferrer"
        className="text-parent"
      >
        <FontAwesomeIcon icon={["fal", iconName]} fixedWidth className="mr-1" />
        {title ? title : <FormattedMessage id={`download.${fileName}`} />}
      </a>
    )
  }

  return (
    <div className="mb-sm" {...other}>
      <a
        href={fileName}
        target="_blank"
        rel="noopener noreferrer"
        className="relative inline-block pl-8 text-gray-dark hover:no-underline hover:text-primary"
      >
        <FontAwesomeIcon
          icon={["far", "file"]}
          fixedWidth
          className="absolute top-0 left-0 mt-1 text-primary"
        />
        <span className="font-bold text-primary">
          {title}
        </span>
        {size && extension && (
          <span className="block text-sm">
            {extension.toUpperCase()},
            {filesize(size, { round: 0 })}
          </span>
        )}
      </a>
    </div>
  )
}

export default injectIntl(Download)
