import React from 'react';
import Layout from './index';

import messages from '../locales/de/messages';

export default (props) => (
    <Layout
    {...props}
    locale="de"
    i18nMessages={messages}
    />
);
