import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../layouts/de"
import Seo from "../../components/seo"
import SimplePage from "../../templates/simple-page"
import Features from "../../components/features"
import Link from "../../components/link"
import BackLink from "../../components/back-link"
import Download from "../../components/download"

const DeliveryProgramPage = () => {
  const { fileNode, image } = useStaticQuery(
    graphql`
      query {
        image: file(
          relativePath: { glob: "delivery-program-forming-tools/*.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        fileNode: sanityDownload(
          file: {de: {fileName: {eq: "Finkernagel Lieferprogramm Umformwerkzeuge"}}}
        ) {
          id
          title {
            de
          }
          file {
            de {
              fileName
              file {
                asset {
                  extension
                  size
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo title="Lieferprogramm Umformwerkzeuge" />
      <SimplePage
        title="Lieferprogramm Umformwerkzeuge"
        prefix={<BackLink to="/forming-tools" />}
      >
        <Download extension={fileNode.file.de.file.asset.extension}
        fileName={`/static/${fileNode.file.de.fileName}.${fileNode.file.de.file.asset.extension}`} 
        key={fileNode.id} 
        title="Lieferprogramm herunterladen"
        size={fileNode.file.de.file.asset.size}/>

        <h2 className="text-black">Zu unseren Produkten zählen</h2>
        <Features
          items={[
            "Komplette Werkzeugsätze für Mehrstufenpressen",
            "Serienwerkzeuge, Werkzeugreparaturen, Um- und Nacharbeiten, Sonderanfertigungen, Full-Service",
            "Maschinenteile, wie Stempel oder Matrizenhalter",
            "Einzelne Werkzeugkomponenten aus Hartmetall oder Stahl",
            "Abgratwerkzeuge",
            "Auswerfer",
            "Pressstempel",
            "Fließpresswerkzeuge",
            "Matrizen (rund, profiliert)",
            "Abgratmesser und Buchsen",
            "Segmentierte Werkzeuge",
            "Vor- und Fertigstaucher",
            "Hilfswerkzeuge: Einzugsrollen, Transportfinger und Greifer",
            "Maschinenteile nach Zeichnung und Muster",
            "Knetwerkzeuge",
          ]}
        />

        <h2 className="text-black">Werkzeuggrößen</h2>
        <Features
          items={[
            "Außendurchmesser: bis Ø 250 mm",
            "Längen: bis 500 mm (Drehteile: bis 1.000 mm)",
            "Bohrungen: von Ø 1 mm bis 200 mm",
          ]}
        />

        <h2 className="text-black">Werkzeugarmierungen</h2>
        <Features
          items={[
            "Führen wir nach Kundenvorgaben und gemäß eigenen Berechnungen durch.",
            "Wir bieten alle gängigen Beschichtungen bedarfsorientiert an.",
          ]}
        />

        <Link to="/contact#forming-tools" className="accessory">
          Kontakt aufnehmen
        </Link>

        {image && (
          <figure className="-mb-2 mt-12">
            <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="" />
          </figure>
        )}
      </SimplePage>
    </Layout>
  )
}

export default DeliveryProgramPage
