export default {
    'title.home': 'Draht und Umformwerkzeuge aus einer Hand',
    'title.company': 'Unternehmen',
    'title.wire': 'Draht',
    'title.forming-tools': 'Umformwerkzeuge',
    'title.jobs': 'Karriere',
    'title.contact': 'Kontakt',
    'title.staff': 'Ansprechpartner',
    'title.news': 'Aktuelles',
    'title.stats': 'In Zahlen',
    'title.environment': 'Umwelt',
    'title.services': 'Unsere Leistungen',
    'title.history': 'Geschichte',
    'title.downloads': 'Downloads',
    'title.privacy': 'Datenschutz',
    'title.imprint': 'Impressum',
    'title.dsgvo': 'Datenschutzerklärung',
    'title.delivery-program.wire': 'Lieferprogramm Draht',
    'title.delivery-program.forming-tools': 'Lieferprogramm Umformwerkzeuge',

    'global.explore': 'Mehr erfahren',
    'global.back': 'Zurück',
    'global.plan-route': 'Link zu Google Maps',
    'global.explore-jobs': 'Mehr zur Karriere bei Finkernagel',
    'global.explore-apprenticeship': 'Mehr zur Ausbildung bei Finkernagel',

    'home.hero.title': 'Weil Draht nicht gleich Draht ist',
    'home.hero.copy': 'Finkernagel – Unser Name steht für anspruchsvolle Kaltstauchdrähte und Umformwerkzeuge in höchster Qualität. Davon profitieren unsere Kunden aus der Automobil-, Bau-, Elektro- und Maschinenbauindustrie weltweit.',
    'home.hero.explore': 'Mehr zum Unternehmen',

    'stat.employees': 'Mitarbeiter*innen',
    'stat.tons-per-month': 'Draht pro Monat',
    'stat.dimension-range': 'Abmessungs­bereich',
    'stat.production-space': 'Produktions­fläche',
    'stat.tools-per-month': 'Werkzeuge pro Monat',
    'stat.solar-modules': 'Photovoltaik-Module',
    'stat.solar-power': 'Photovoltaik-Anlagenleistung',
    'stat.co2-savings': 'CO₂-Vermeidung pro Jahr',
    'stat.co2-savings-percent': "CO₂-Vermeidung",
    'stat.resources': "Kreislauf",
    'stat.digitalisation': "Blatt Papier pro Jahr",
    'stat.logistics': 'Engagement',
    'stat.mobility':'Ladepunkte',

    'history.1738': 'Erstmalige Erwähnung eines Drahtwerks an unserem Standort als „Pastors Rolle“',
    'history.1926': 'Übernahme durch Fritz Finkernagel',
    'history.1946': 'Einstieg von Sohn Friedrich-Wilhelm Finkernagel (rechts)',
    'history.1963': 'Gründung Abteilung Umformwerkzeuge',
    'history.1984': 'Mit Fritz Uwe Finkernagel (rechts) Einstieg der dritten Generation',
    'history.1991': 'Erweiterung der Abmessungspalette durch Inbetriebnahme eines Horizontalzuges',
    'history.1993': 'Inbetriebnahme einer der ersten HICON/H₂-Haubenglühanlagen Europas für Kaltstauchdrähte',
    'history.1997': 'Inbetriebnahme der vollautomatischen Beiz- und Beschichtungsanlage',
    'history.2005': 'Betriebserweiterung durch Neubau mit 4.000 m² Produktionsfläche',
    'history.2015': 'Mit Timo Finkernagel Einstieg der vierten Generation',
    'history.2017': 'Neubau Umformwerkzeuge und Erweiterung des Drahtlagers, insgesamt 3.000 m²',

    'page.home.description': 'Finkernagel – Unser Name steht für anspruchsvolle Kaltstauchdrähte und Umformwerkzeuge in höchster Qualität.',
    'page.home.keywords': 'Kaltstauchdraht, Umformwerkzeuge',
    'page.home.archive': 'Zum Archiv',
    'page.home.sustainability.title': 'Nachhaltigkeit',
    'page.home.sustainability.paragraph': 'Seit Generationen ist es für uns eine Selbstverständlichkeit, möglichst ressourcenschonend zu arbeiten. Die Reduzierung des CO₂-Fußabdrucks ist aber nur möglich, wenn die gesamte Lieferkette betrachtet und daraufhin optimiert wird.',
    'page.home.sustainability.subheading':'Sechs Ebenen zur Reduzierung des CO₂‑Fußabdrucks',
    'page.home.sustainability.material.title': 'Vormaterial',
    'page.home.sustainability.material.paragraph': 'Als Partner vieler renommierter Stahllieferanten bieten wir recycelt und erneuerbar hergestellten Draht, wie z.B. XCarb® von ArcelorMittal. Über 80 Prozent CO₂-Einsparungen sind damit möglich.',
    'page.home.sustainability.energy.title': 'Energie',
    'page.home.sustainability.energy.paragraph': 'Mit dem auf unseren Hallendächern erzeugten Strom decken wir mehr als 20% unseres Strombedarfs ab.',
    'page.home.sustainability.resources.title': 'Ressourceneffizienz',
    'page.home.sustainability.resources.paragraph': 'Wir bauen den Einsatz von Wasserstoff für unsere Wärmebehandlung kontinuierlich aus und nutzen die Abwärme unserer Glühanlagen.',
    'page.home.sustainability.digitalisation.title': 'Digitalisierung',
    'page.home.sustainability.digitalisation.paragraph': 'Durch die fortwährende Digitalisierung von Unternehmensprozessen sparen wir Jahr für Jahr Papier ein, z.B. durch die Einführung digitaler interner und externer Prüfunterlagen.',
    'page.home.sustainability.logistics.title': 'Logistik',
    'page.home.sustainability.logistics.paragraph': 'Optimierung der Drahtlogistik, z.B. durch höhere Auslastung der Lieferfahrzeuge.',
    'page.home.sustainability.mobility.title': 'Mobilität',
    'page.home.sustainability.mobility.paragraph': 'Installation von Ladepunkten für Elektroautos und sukzessive Umstellung des Fuhrparks auf rein elektrisch.',
    'page.home.sustainability.download': 'Nachhaltigkeit bei Finkernagel',
    'page.home.sustainability.download.link': '/static/Nachhaltigkeit.pdf',

    'page.company.description': 'Finkernagel bedient die Zulieferer der Automobil-, Bau-, Elektro- und Maschinenbauindustrie mit Kaltstauchdraht und Umformwerkzeugen für höchste Anforderungen.',
    'page.company.keywords': 'Kaltstauchdraht, EN 10263, Draht, Kaltumformung, Umformwerkzeuge, Kaltmassivumformung, Matrizen',

    'page.wire.description': 'Werfen Sie einen Blick auf unsere innovative Drahtproduktion und die Vielzahl der möglichen Lieferformen.',
    'page.wire.keywords': 'Kaltstauchdraht, EN 10263, Draht, Kaltumformung',

    'page.forming-tools.description': 'Wir liefern Umformwerkzeuge aus Hartmetall und Stahl in den unterschiedlichsten Ausführungen und Größen.',
    'page.forming-tools.keywords': 'Umformwerkzeuge, Kaltmassivumformung, Matrizen',

    'page.contact.headline': 'Wir stehen mit Rat und Draht zur Seite',
    'page.contact.description': 'Wir stehen Ihnen mit Rat und Draht zur Seite. Hier finden Sie alle Ansprechpartner bei Finkernagel in Altena auf einen Blick.',
    'page.contact.business-card': 'Digitale Visitenkarte',
    
    'page.downloads.description': 'Finkernagel Downloads',

    'page.privacy.description': 'Alle Informationen zur Verarbeitung von Daten durch Firma Finkernagel.',

    'page.jobs.headline': 'Karriere bei Finkernagel',
    'page.jobs.description': 'Sie suchen eine neue Herausforderung in einem innovativen Arbeitsumfeld? Dann lernen Sie Finkernagel in Altena kennen.',
    'page.jobs.survey.description': 'unserer Mitarbeiter:innen empfehlen Finkernagel als Arbeitgeber.',
    'page.jobs.survey.source': 'gemäß aktueller Mitarbeiter:innen-Umfrage',

    'page.jobs.our-values.title': 'Unsere Werte',
    'page.jobs.our-values.spirit.title': 'Tatkraft',
    'page.jobs.our-values.spirit.paragraph': 'Wenn es darum geht, Kunden­anforderungen zu erfüllen, zeigen wir Tatkraft und volle Einsatz­bereitschaft. Damit haben wir es schon oft geschafft, das Unmögliche in die Tat umzusetzen.',
    'page.jobs.our-values.precision.title': 'Präzision',
    'page.jobs.our-values.precision.paragraph': 'Wir stehen für höchste Qualität und Präzision in allen Teilen, die unsere Produktion verlassen. Bei uns kommt es auf jedes μm an, wir entdecken nicht nur Fehler, sondern markieren sie direkt, damit eine präzise Weiter­verarbeitung möglich ist.',
    'page.jobs.our-values.keep-word.title': 'Wort halten',
    'page.jobs.our-values.keep-word.paragraph': 'Wenn wir unser Wort geben, dann ist das ein Versprechen. Wir handeln zuverlässig, transparent und verantwortungs­bewusst gegenüber Kunden, Partnern und selbst­verständlich auch innerhalb von Finkernagel.',
    'page.jobs.our-values.foresight.title': 'Weitsicht',
    'page.jobs.our-values.foresight.paragraph': 'Wir sind stolz auf unsere fast einhundert­jährige Firmengeschichte. Lang­fristiger Erfolg und Nachhaltig­keit sind uns als Familien­unternehmen besonders wichtig. Daher arbeiten wir bereits in vierter Generation mit Weitsicht an innovativen Lösungen für die Zukunft.',
   

    'page.news.description': 'Aktuelles von Finkernagel',
}
