import React from "react"

const Features = ({ items, className, ...other }) => {
    return (
        <ul className={`mb-sm ${className || ''}`} {...other}>
            {items.map((item, i) => (
                <li key={i} className="relative pl-6">
                    <span className="inline-block absolute left-0 top-0 -mt-px">
                        —
                    </span>
                    {item}
                </li>
            ))}
        </ul>
    )
}

export default Features
